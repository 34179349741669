<template>
    <div class="body_bg">
        <div class="body">
            <div class="body_in">
                <div class="title">

                </div>
                <div class="container">
                    <form @submit.prevent="loginSubmit">
                        <div class="form-control">
                            <input type="text" placeholder="请输入账号" v-model="username" required>
                            <label class="el-user"></label>
                            <!-- <label>
                              <span style="transition-delay: 0ms">E</span>
                                <span style="transition-delay: 50ms">m</span>
                                <span style="transition-delay: 100ms">a</span>
                                <span style="transition-delay: 150ms">i</span>
                                <span style="transition-delay: 200ms">l</span>
                          </label> -->
                        </div>

                        <div class="form-control">
                            <input type="password" placeholder="请输入密码" v-model="password" required>
                            <label class="el-password"></label>
                        </div>

                        <button class="btn">登&emsp;&emsp;录</button>

                        <!--                <p class="text">Don't have an account? <a
    href="#">Register</a> </p>-->
                    </form>
                </div>

            </div>
        </div>
        <div class="login_logo"><img src="../assets/img/login_2_logo.png"></div>
    </div>
</template>

<script>
    import qs from "qs";

    export default {
        name: 'Login',
        mounted() {
//             const labels = document.querySelectorAll('.form-control label')
//             labels.forEach(label => {
//                 label.innerHTML = label.innerText
//                     .split('')
//                     .map((letter, idx) => `<span style="transition-delay:${idx * 50}ms">${letter}
// </span>`)
//                     .join('')
//             })
        },
        data(){
            return {
                username: '',
                password: '',
            }
        },
        methods:{
            loginSubmit() {
                this.$axios.post("/login",{
                    name: this.username.trim(),
                    password: this.password.trim(),
                    orgType: 1,
                }).then(res=>{
                // this.$axios.post("/login?"+qs.stringify({username:this.username.trim(),password:this.password.trim()})).then(res=>{
                    const jwt = res.headers['authorization'];
                    if(jwt){
                        localStorage.setItem('dscsToken', jwt);
                        let redirect = this.$route.query.redirect;
                        if(!redirect) redirect = '/';
                        this.$router.push({path: redirect});
                    }
                });
            }
        }
    }

</script>

<style>
    * {
        box-sizing: border-box;
    }
    .body_bg {
        background: url("../assets/img/login_2.jpg") center no-repeat;
        background-size: cover;
        height: 100vh;
        width: 100vw;
    }
    .body {

        color: #fff;
        font-family: 'Muli', sans-serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100vh;
        overflow: hidden;
        margin: 0;
    }
    .title{
        padding: 150px 40px 10px;
        border-radius: 5px 5px 0 0;
    }
    .title img{
        width: 100%;
    }

    .container{
        padding: 20px 40px;
    }
    .container h1 {
        text-align: center;
        margin-bottom: 30px;
    }

    .container a {
        text-decoration: none;
        color: lightblue;
    }

    .btn {
        cursor: pointer;
        display: block;
        width: 250px;
        background: url("../assets/img/but_bg.png");
        padding: 20px;
        font-family: inherit;
        font-size: 18px;
        border: 0;
        border-radius: 26px;
        color: #fff;
        margin: auto;

        text-align: center;
    }

    .btn:focus {
        outline: 0;
    }

    .btn:active {
        transform: scale(0.98);
    }

    .text {
        margin-top: 30px;
    }
    .form-control {
        position: relative;
        width: 250px;
        margin: 40px auto;
    }

    .form-control input {
        background-color: transparent;
        border: 2px #00f3fb solid;
        display: block;
        width: 250px;
        padding: 20px 0px 20px 50px;
        font-size: 18px;
        color: #fff;
        border-radius: 26px;

    }

    .form-control input:focus,
    .form-control input:valid {
        outline: 0;
    }

    .form-control label {
        position: absolute;
        top: 20%;
        left: 10px;
        pointer-events: none;
        overflow: hidden;
        padding: 20px 0px 20px 40px;
    }

    .form-control label span {
        display: inline-block;
        font-size: 18px;
        min-width: 5px;
        font-size: 18px;

    }

    .form-control input:focus + label span,
    .form-control input:valid + label span {
        color: #fff;
        transform: translateY(-60px);
    }

    input:-webkit-autofill {
        -webkit-text-fill-color: #fff !important;
        -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
        background-color: transparent;
        background-image: none;
        transition: background-color 50000s ease-in-out 0s;
    }

    input {
        background-color: transparent;
        caret-color: #fff;
        padding-left: 10px;
    }
    .el-password, .el-user {
        background: url('../assets/img/password_w.png') left center no-repeat;
    }

    .el-user {
        background: url('../assets/img/user_w.png') left center no-repeat;
    }
    .login_logo{
        position: fixed;
        bottom: 20px;
        right: 30px;
        width: 30vw;
    }
    .login_logo img{
        width: 100%;
    }
</style>